var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-list",
                    {
                      staticClass: "pa-0",
                      attrs: { nav: "", color: "transparent" },
                    },
                    [
                      _c(
                        "v-list-item",
                        _vm._g(
                          {
                            on: {
                              click: function ($event) {
                                return _vm.gotoAssignment(
                                  _vm.assignmentData.bid
                                )
                              },
                            },
                          },
                          on
                        ),
                        [
                          _vm.isMasterInstance
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { large: "", color: "secondary" },
                                    },
                                    [_vm._v("assignment")]
                                  ),
                                ],
                                1
                              )
                            : !_vm.isMasterInstance
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _vm.assignmentData.handins.length
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            large: "",
                                            color: "success",
                                          },
                                        },
                                        [_vm._v("assignment_turned_in")]
                                      )
                                    : _vm.isAssignmentOverdue(
                                        _vm.assignmentData.allow_handins_until
                                      )
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            large: "",
                                            color: "warning",
                                          },
                                        },
                                        [_vm._v(" assignment_late ")]
                                      )
                                    : _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { large: "", color: "orange" },
                                        },
                                        [_vm._v("assignment_late")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "secondary--text subtitle-1 font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.assignmentData.long_id) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-list-item-subtitle", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-center caption",
                                  },
                                  [
                                    _vm._v(
                                      "Due on " +
                                        _vm._s(
                                          _vm._f("dateTimeToHuman")(
                                            _vm.assignmentData
                                              .allow_handins_until
                                          )
                                        )
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          !_vm.isMasterInstance
            ? _c("div", [
                _vm.assignmentData.handins.length
                  ? _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("span", [
                        _vm._v(
                          "Submitted on " +
                            _vm._s(
                              _vm._f("dateTimeToHuman")(
                                _vm.assignmentData.handins[0].handin_timestamp
                              )
                            )
                        ),
                      ]),
                      _c("span", { staticClass: "caption font-weight-bold" }, [
                        _vm._v("Click to see all submissions"),
                      ]),
                    ])
                  : _vm.isAssignmentOverdue(
                      _vm.assignmentData.allow_handins_until
                    )
                  ? _c("span", [_vm._v("Overdue without submission")])
                  : _c("span", [_vm._v("Submission due")]),
              ])
            : _c("div", [_vm._v("Open assignment")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }